import React, { useRef, useContext, useMemo } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import styled from "@emotion/styled";
import ShareAction from "./ShareAction";
import { PlaceContext } from "../../data/MapContext";
import offsetMap from "./OffsetsList/offsetMap";
import logo from "./logo_100h_trans.png";

const ICON_HEIGHT = 120;
const actionItemsMap = [
  {
    id: 0,
    heading: "Share on Social Media",
    body: "Download and share this on social media to spread the word.",
    buttonText: "Share Now",
    buttonUrl: "/",
    component: ShareAction,
    type: "share",
  },
  {
    id: 1,
    heading: "Contact your Representative",
    body: "Tell your congressperson that you don't want your tax dollars to fund Israel's violence.",
    buttonText: "Take Action",
    buttonUrl: "https://act.uscpr.org/a/%20stop-funding-israels-massacres",
    type: "contact",
  },
  {
    id: 2,
    heading: "Donate",
    body: "On average, $25.25 of your tax dollars go to fund Israel's violence. Offset your taxes by funding Palestinian liberation.",
    buttonText: "Donate",
    buttonUrl: "https://secure.everyaction.com/FMgcVfTaOEK1erm4bbhWyQ2",
    type: "donate",
  },
];

const StyledActionButton = styled(Button)(({ theme, href }) => ({
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.secondary.main,
  fontWeight: 800,
  letterSpacing: "0.38px",
  fontSize: "12px",
  fontFamily: "ralewayextrabold",

  "&:hover": {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.main,
  },
  "&:focus": {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.main,
  },
  "& .MuiButtonBase-root": {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.main,
  },
  "& .MuiButtonBase-root:focus": {
    backgroundColor: "transparent !important",
  },
  href: href,
}));

const ActionItems = () => {
  const { selectedPlace } = useContext(PlaceContext);
  const canvasRef = useRef();
  const offsetKeys = Object.keys(offsetMap);
  const iconRefs = useMemo(
    () =>
      Array(offsetKeys.length)
        .fill(0)
        .map((i) => React.createRef()),
    [offsetKeys.length]
  );
  const actionClickHandler = ({ type, event }) => {
    if (type === "share") {
      let link = event.currentTarget;
      link.setAttribute(
        "download",
        selectedPlace.name.replace(/ /g, "_").replace(/,/g, "-") +
          ":US_Military_Funding_To_Israel.png"
      );
      let image = canvasRef.current.toDataURL("image/png");
      link.setAttribute("href", image);
    }
  };

  const wrapTitleText = (context, text) => {
    let x = 800;
    let y = 90;
    var words = text.split(" ");
    let lineHeight = 80;
    let fontSize, lineCount, redrawn;
    let maxWidth = 1100;
    context.textAlign = "center";
    const determineFontSize = () => {
      context.beginPath();
      context.rect(0, 0, 1600, 1600);
      context.fillStyle = "#EE3624";
      context.fill();
      context.fillStyle = "white";
      fontSize = lineHeight * 0.85;
      context.font = `${fontSize}px ralewayextrabold`; //ralewaymedium

      lineCount = 1;
      var line = "";
      for (var n = 0; n < words.length; n++) {
        var testLine = line + words[n] + " ";
        var metrics = context.measureText(testLine);
        var testWidth = metrics.width;
        if (testWidth > maxWidth && n > 0) {
          context.fillText(line, x, y);
          line = words[n] + " ";
          y += lineHeight;
          lineCount++;
        } else {
          line = testLine;
        }
      }
      if (lineCount < 4) {
        if (redrawn) {
          context.fillText(line, x, y);
          return;
        } else {
          y = 130;
          redrawn = true;
          determineFontSize();
        }
      } else if (lineCount === 4) {
        context.fillText(line, x, y);
        return;
      } else {
        lineHeight -= 2;
        y = 110;
        determineFontSize();
      }
    };
    determineFontSize();
  };

  const drawIcon = (context, index, x, y) => {
    var svg = iconRefs[index].current;
    if (!svg) return;
    // get svg data
    var xml = new XMLSerializer().serializeToString(svg);

    // make it base64
    var svg64 = btoa(xml);
    var b64Start = "data:image/svg+xml;base64,";

    // prepend a "header"
    var image64 = b64Start + svg64;
    let img = new Image();
    img.src = image64;
    img.onload = () => context.drawImage(img, x, y);
  };

  const wrapText = (context, text, x, y, maxWidth, lineHeight) => {
    var words = text.split(" ");
    var line = "";
    let lineCount = 1;

    const fontSize = lineHeight * 0.85;
    context.font = `${fontSize}px ralewaymedium`; //
    for (var n = 0; n < words.length; n++) {
      var testLine = line + words[n] + " ";
      var metrics = context.measureText(testLine);
      var testWidth = metrics.width;
      if (testWidth > maxWidth && n > 0) {
        context.fillText(line, x, y);
        line = words[n] + " ";
        y += lineHeight;
        lineCount++;
      } else {
        line = testLine;
      }
    }
    context.fillText(line, x, y);
    return lineCount;
  };

  const addOffsetList = (context) => {
    context.fillStyle = "black";

    let itemsLength = offsetKeys.length; //6
    let boxHeight = 191;

    let gapSize = -(1015 - itemsLength * boxHeight) / itemsLength + 1;
    let y = 395 + gapSize / 2;

    offsetKeys.forEach((offset, index) => {
      // const fontSize = 56 + (5 - itemsLength) * 10;
      context.fillStyle = "#FFFFFF";
      context.fillRect(0, y - 90, 1600, boxHeight);
      context.fillStyle = "#222222";
      context.textAlign = "left";
      drawIcon(context, index, 75, y - 75);
      context.font = `22px ralewaymedium`;
      wrapText(context, offsetMap[offset].text, 650, y, 940, 75);
      context.textAlign = "right";
      const contribution = offsetMap[offset].unitCost(
        selectedPlace.contribution
      );
      wrapText(context, contribution, 600, y, 800, 70);
      y += boxHeight + gapSize;
    });
  };

  const addLogoBlock = (context) => {
    context.beginPath();
    context.rect(0, 1600, 1600, 200);
    context.fillStyle = "white";
    context.fill();
    context.font = "36px ralewaysemi_bold";
    context.fillStyle = "#000000";
    context.textAlign = "center";
    let text = "uscpr.org/militaryfunding"
    wrapText(context, text, 800 - 30, 1730, 1000, 36);
    context.font = "25px raleway";
    context.fillStyle = "#353535";

    text =
      `*This graphic shows an estimate of how much military funding to Israel is provided by people in ${selectedPlace.name} through their federal tax dollars.`;
    wrapText(context, text, 800, 1785, 1500, 25);
    const imageObj = new Image();
    imageObj.src = logo;

    imageObj.onload = () => {
      context.drawImage(imageObj, 800 - 217, 1620, 435 * 0.8, 100 * 0.8);
    };
  };

  if (selectedPlace && canvasRef?.current) {
    const context = canvasRef.current.getContext("2d");
    context.beginPath();
    context.rect(0, 0, 1600, 1600);
    context.fillStyle = "#EE3624";
    context.fill();
    const text = `In ${selectedPlace.name}, $${parseInt(
      selectedPlace.contribution
    ).toLocaleString()} to Israel's weapons could instead fund:`;
    wrapTitleText(context, text);
    addOffsetList(context);
    addLogoBlock(context);
  }

  return actionItemsMap.map((item) => {
    return (
      <React.Fragment key={item.id}>
        <Grid container spacing={4} sx={{ pb: 1.7, pt: 1.7, pl: 2 }}>
          <Grid item xs={12} sm={8}>
            <Typography
              variant="subtitle2"
              sx={{ fontFamily: "ralewayextrabold" }}
            >
              {item.heading}
            </Typography>
            <Typography variant="body2" color="secondary.main">
              {item.body}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <StyledActionButton
              href={item.buttonUrl}
              onClick={(event) =>
                actionClickHandler({ event, type: item.type })
              }
              target="blank"
            >
              {item.buttonText}
            </StyledActionButton>
          </Grid>
        </Grid>
        {item.id < actionItemsMap.length - 1 && (
          <Divider
            sx={{
              borderColor: "maroon",
            }}
          />
        )}
        {item.component && (
          <div style={{ display: "none" }}>
            <canvas
              ref={canvasRef}
              id="myCanvas"
              width="1600px"
              height="1800px"
              style={{ position: "absolute", bottom: -1640, left: -865 }}
            />
            {offsetKeys.map((item, i) =>
              React.createElement(offsetMap[item].icon, {
                key: item,
                ref: iconRefs[i],
                width: `${ICON_HEIGHT}px`,
                height: `${ICON_HEIGHT}px`,
              })
            )}
          </div>
        )}
      </React.Fragment>
    );
  });
};
export default ActionItems;
