import SearchBar from "./SearchBar";
import TabsNav from "./TabsNav";
import {useState} from "react"
const ControlsColumn = () => {
  // const classes = useStyles();
  const [actionsOpen,setActionsOpen] = useState(false)
  return (
    <>
      <SearchBar actionsOpen={actionsOpen} />
      <TabsNav actionsOpen={actionsOpen} setActionsOpen={setActionsOpen}/>
    </>
  );
};

export default ControlsColumn;
