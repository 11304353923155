import React, { useContext } from "react";
import { Typography } from "@mui/material";
import List from "@mui/material/List";

import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import { PlaceContext } from "../../../data/MapContext";
import offsetMap from "./offsetMap";
import {US_TOTAL} from "../../../constants";

const ListItemWrapper = ({ offset }) => {
  const { selectedPlace } = useContext(PlaceContext);
  const total = selectedPlace.contribution
    ? selectedPlace.contribution
    : US_TOTAL;
  return (
    <>
      <ListItem divider>
        <ListItemIcon>
          {React.createElement(offsetMap[offset].icon, {})}
        </ListItemIcon>
        <Grid container spacing={{ xs: 0.5, sm: 2 }}>
          <Grid item xs={12} sm={4}>
            <Typography
              color="secondary"
              sx={{
                wordWrap: "normal",
                fontFamily: "Oswald",
                fontSize: "26px",
                fontWeight: 700,
                letterSpacing: "0.81px",
              }}
            >
              {offsetMap[offset].unitCost(total)}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={8}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Typography color="secondary" variant="body2">
              {offsetMap[offset].text}
            </Typography>
          </Grid>
        </Grid>
      </ListItem>
      <Divider
        sx={{
          borderColor: "midGrey",
        }}
      />
    </>
  );
};

const OffsetsList = () => {
  const { selectedPlace } = useContext(PlaceContext);

  return (
    <>
      <Typography color="secondary" variant="subtitle1">
        In {selectedPlace.name}, $
        {parseInt(selectedPlace.contribution).toLocaleString()} to Israel's
        weapons could instead fund one of the following:
      </Typography>
      <List>
        {Object.keys(offsetMap).map((key) => {
          return <ListItemWrapper offset={key} key={key} />;
        })}
      </List>
    </>
  );
};

export default OffsetsList;
