import React, {
  useContext,
  useEffect,
  useCallback,
  useRef,
  useState,
} from "react";
import { styled } from "@mui/material/styles";
import { MapContainer, TileLayer, GeoJSON, Marker, Popup } from "react-leaflet";
import { useLeafletContext } from "@react-leaflet/core";
import L from "leaflet";

import { statesData } from "../data/us-states";
import { PlaceContext, useMapData } from "../data/MapContext";
import useMediaQuery from "@mui/material/useMediaQuery";

const usCenter = [37.8, -96];
const currencyStringToNumber = (str) => Number(str.replace(/[^0-9.-]+/g, ""));

/*
opacity: 1;
background-color: rgba(238,54,36, 1);
*/

function useStableCallback(callback) {
  const callbackRef = useRef(callback);
  callbackRef.current = callback;

  const stableCallback = useCallback((...args) => {
    return callbackRef.current(...args);
  }, []);

  return stableCallback;
}

const StyledPopup = styled((props) => <Popup {...props} />)(({ theme }) => ({
  // backgroundColor: "red",
  // left:"20px",
  borderRadius: 0,
  background: "transparent",

  "&.leaflet-popup": {
    left: "0px !important",
  },
  "& .leaflet-popup-content-wrapper": {
    borderRadius: 0,
    background: "transparent",
    color: "white",
    fontFamily: "Raleway",
    fontWeight: 600,
    fontSize: "16px",
    letterSpacing: "0.44px",
    paddingLeft: "11px", //calc(50% + 11px)",
    boxShadow: "none",
  },
  "& .leaflet-popup-content": {
    background: theme.palette.primary.main,
    padding: "8px 12px",
    margin: 0,
    width: " !important",
  },
  "& .leaflet-popup-tip-container": {
    left: 0,
    width: "42px",
    height: "15px",
    marginLeft: "11px", //"calc(50% + 11px)",
    marginTop: "-2px",
  },

  "& .leaflet-popup-tip": {
    background: theme.palette.primary.main,
    transform: "rotate(68deg)",
    margin: "-13px -5px 0",
  },
}));

const icon = L.divIcon({
  className: "custom-icon",
  //  popupAnchor: [130, 0] ,

  html: `<?xml version="1.0" encoding="UTF-8"?>
  <svg width="33px" height="41px" viewBox="0 0 33 41" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <title>icons/mapmarker/red</title>
      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="Artboard" transform="translate(-833.000000, -1665.000000)" fill="#EE3624" fill-rule="nonzero">
              <g id="location_on_FILL0_wght600_GRAD0_opsz48" transform="translate(833.000000, 1665.000000)">
                  <path d="M16.47535,20.15 C17.4830833,20.15 18.3474667,19.7912 19.0685,19.0736 C19.7895,18.3559667 20.15,17.4932833 20.15,16.48555 C20.15,15.4778167 19.7912,14.6134333 19.0736,13.8924 C18.3559667,13.1714 17.4932833,12.8109 16.48555,12.8109 C15.4778167,12.8109 14.6134333,13.1697 13.8924,13.8873 C13.1714,14.6049333 12.8109,15.4676167 12.8109,16.47535 C12.8109,17.4830833 13.1697,18.3474667 13.8873,19.0685 C14.6049333,19.7895 15.4676167,20.15 16.47535,20.15 L16.47535,20.15 Z M16.48045,35.8804 C20.75095,31.9726333 23.9051667,28.4283667 25.9431,25.2476 C27.9810333,22.0668667 29,19.2747833 29,16.87135 C29,13.08845 27.7921333,9.99098333 25.3764,7.57895 C22.9606667,5.16691667 19.9974833,3.9609 16.48685,3.9609 C12.9762167,3.9609 10.0109,5.1675 7.5909,7.5807 C5.1709,9.99386667 3.9609,13.0928167 3.9609,16.87755 C3.9609,19.2852833 5.0051,22.0748167 7.0935,25.24615 C9.1819,28.4174833 12.3108833,31.9622333 16.48045,35.8804 Z M16.4984,40.24785 C16.1922333,40.24785 15.89675,40.2003833 15.61195,40.10545 C15.3271833,40.0105167 15.0732,39.8681333 14.85,39.6783 C9.8645,35.2560667 6.14311667,31.1656667 3.68585,27.4071 C1.22861667,23.6485667 1.42108547e-15,20.1396833 1.42108547e-15,16.88045 C1.42108547e-15,11.7339833 1.65636667,7.63395 4.9691,4.58035 C8.28183333,1.52678333 12.1180833,1.42108547e-15 16.47785,1.42108547e-15 C20.8376167,1.42108547e-15 24.6747333,1.52678333 27.9892,4.58035 C31.3036667,7.63395 32.9609,11.7339833 32.9609,16.88045 C32.9609,20.1326167 31.7322833,23.63975 29.27505,27.40185 C26.8177833,31.1639167 23.0964,35.2560667 18.1109,39.6783 C17.8877,39.8681333 17.6397,40.0105167 17.3669,40.10545 C17.0940667,40.2003833 16.8045667,40.24785 16.4984,40.24785 Z" id="Shape"></path>
              </g>
          </g>
      </g>
  </svg>`,
});

const Mover = (props) => {
  const context = useLeafletContext();
  const { placeBounds, selectedPlace, matches, map } = props;
  const [center, setCenter] = useState(usCenter);
  const markerRef = useRef();

  const container = context.layerContainer || context.map;
  useEffect(() => {
    map.on("moveend", function (e) {
      setCenter(map.getCenter());
    });
    if (selectedPlace.type === "city") {
      container.setView(selectedPlace.latLng, 12, { animation: true });
    }
    if (selectedPlace.type === "country") {
      container.setView(usCenter, matches ? 4 : 3, { animation: true });
    }

    if (placeBounds?.length > 0) {
      let flatPlaceBounds;
      if (placeBounds.length === 1) {
        flatPlaceBounds = placeBounds[0];
      } else if (placeBounds.length > 1) flatPlaceBounds = placeBounds.flat(2);
      const b = L.GeoJSON.latLngsToCoords(flatPlaceBounds);
      container.fitBounds(b, { maxZoom: 13 });
    }
    setTimeout(() => {
      map.off("moveend");
      const marker = markerRef.current;
      if (marker) {
        marker.openPopup();
      }
    }, 1000);
    return () => {};
  }, [selectedPlace]);
  const name = selectedPlace?.name ? selectedPlace.name : "The U.S.";

  return (
    selectedPlace.type !== "country" &&<Marker ref={markerRef} position={center} icon={icon}>
      <StyledPopup closeButton={false}>{name}</StyledPopup>
    </Marker>
  );
};

const defaultStateStyle = { weight: 0.2, color: "white", fillOpacity: 0 };
const hoverStateStyle = { weight: 0.95, color: "#B8B8B8", fillOpacity: 0 };

const MainMap = () => {
  const { data } = useMapData();
  return <MainMapElement data={data} />;
};

const MainMapElement = ({ data }) => {
  const { selectedPlace, setSelectedPlace } = useContext(PlaceContext);
  const [map, setMap] = useState(null);

  const matches = useMediaQuery("(min-width:600px)");

  const getPlaceBounds = (selectedPlace) => {
    const bounds = statesData.features.filter(
      (item) => item?.properties.name === selectedPlace?.name
    );
    if (bounds.length === 1) {
      return bounds[0].geometry?.coordinates;
    } else {
      return null;
    }
  };
  const highlightFeature = (e) => {
    const targetName = e.target.feature.properties.name;
    if (selectedPlace.name !== targetName) {
      var layer = e.target;
      layer.setStyle(hoverStateStyle);
      layer.bringToFront();
    }
  };
  const resetHighlight = (e) => {
    const targetName = e.target.feature.properties.name;
    if (selectedPlace.name !== targetName) {
      const layer = e.target;
      layer.setStyle(defaultStateStyle);
    }
  };
  const stableHighlightFeature = useStableCallback(highlightFeature);
  const stableResetHighlight = useStableCallback(resetHighlight);

  const stateClicked = (e) => {
    const jsonData = JSON.parse(data.map);
    const clickedName = e.target.feature.properties.name;
    const stateData = jsonData.filter((item) => item.name === clickedName); //
    const { name, contribution, code } = stateData[0];
    setSelectedPlace({
      type: "state",
      name,
      contribution: currencyStringToNumber(contribution),
      code,
    });
  };

  const onEachFeature = (feature, layer) => {
    layer.on({
      mouseover: stableHighlightFeature,
      mouseout: stableResetHighlight,
      click: stateClicked,
    });
  };

  const placeBounds = getPlaceBounds(selectedPlace);

  const style = (feature) => {
    return {
      weight: feature.properties.name === selectedPlace.name ? 1.15 : 0.2,
      color: "white",
      fillOpacity: 0,
    };
  };

  return (
    <div>
      <MapContainer
        center={usCenter}
        ref={setMap}
        // zoom={matches ? 13 : 14}
        maxZoom={15}
        scrollWheelZoom={false}
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">USCPR</a>'
      >
        <TileLayer
          attribution='<a href="https://jawg.io?utm_medium=map&utm_source=attribution" title="Tiles Courtesy of Jawg Maps" target="_blank" class="jawg-attrib" >&copy; <b>Jawg</b>Maps</a> | <a href="https://www.openstreetmap.org/copyright" title="OpenStreetMap is open data licensed under ODbL" target="_blank" class="osm-attrib">&copy; OSM contributors</a>'
          url="https://{s}.tile.jawg.io/jawg-dark/{z}/{x}/{y}{r}.png?access-token=eOArNZ9rwY2Lmn930EjR1RIQ87pTeNtozZuc4p5krujuUcKYQhQZdePwxBJgQDB1"
          // url="https://stamen-tiles-{s}.a.ssl.fastly.net/toner/{z}/{x}/{y}{r}.png"
          subdomains="abcd"
          maxZoom={20}
          accessToken="eOArNZ9rwY2Lmn930EjR1RIQ87pTeNtozZuc4p5krujuUcKYQhQZdePwxBJgQDB1"
        />
        {data && (
          <>
            <GeoJSON
              data={statesData}
              style={style}
              onEachFeature={onEachFeature}
            />
          </>
        )}

        {map ? (
          <Mover
            center={usCenter}
            size={0}
            placeBounds={placeBounds}
            selectedPlace={selectedPlace}
            matches={matches}
            map={map}
          />
        ) : null}
      </MapContainer>
    </div>
  );
};

export default MainMap;
