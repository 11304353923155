import React, { useState, useEffect, useContext, useMemo } from "react";
import { gql, useQuery } from "@apollo/client";

import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";

import Autocomplete from "@mui/material/Autocomplete";

import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { useMapData, PlaceContext } from "../../data/MapContext";
import debounce from "lodash/debounce";
import { ReactComponent as SearchIcon } from "./OffsetsList/icons/search.svg";
const usTotal = 3800000000;

const StyledCustomPaper = styled(Paper)({
  "& .MuiAutocomplete-groupLabel": {
    backgroundColor: "white",
    lineHeight: "16px",
  },
  "& .MuiAutocomplete-groupUl": {
    marginTop: "0px !important",
    marginBottom: "0px !important",
  },
  "& .MuiAutocomplete-listbox": {
    marginTop: "0px !important",
    marginBottom: "0px !important",
  },
});

const CssTextField = styled((props) => <TextField {...props} />)(
  ({ theme }) => ({
    width: "calc(100% - 10px)",
    marginTop: 20,
    color: "white",
    borderRadius: 11,
    fontFamily: "ralewaymedium",
    fontWeight: 500,
    fontSize: "14px",
    letterSpacing: "0.44px",
    "& .MuiInputBase-root": {
      color: "white",
      borderRadius: 11,
      fontWeight: 500,
      letterSpacing: "0.44px",
      fontSize: "14px",
      borderColor: "white",
      "&:hover": {
        borderColor: "white",
      },
    },

    "& .MuiInputBase-input": {
      fontFamily: "ralewaymedium",
      fontWeight: 500,
      fontSize: "14px",
      color: "white",
      border: "white",
      backgroundColor: "transparent",
      letterSpacing: "0.44px",
    },
    "& .MuiAutocomplete-inputFocused": {
      fontSize: "14px",
      color: "white",
      border: "none",
      backgroundColor: "transparent !important",
      fontFamily: "Raleway",
    },
    "& .MuiAutocomplete-groupUl": {
      marginTop: "0px",
      marginBottom: "0px",
      fontFamily: "ralewaymedium",
      "& .MuiAutocomplete-option": {
        fontFamily: "ralewaymedium !important",
        paddingTop: "0px !important",
        paddingBottom: "0px !important",
      },
    },

    "& label.Mui-focused": {
      color: "white",
    },
    "& .MuiOutlinedInput-root": {
      paddingRight: "14px !important",
      "& fieldset": {
        borderColor: "white",
      },
      "&:hover fieldset": {
        borderColor: "#B8B8B8",
      },
      "&:active fieldset": {
        borderColor: "#B8B8B8",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#B8B8B8",
      },
    },
  })
);

const CustomPaper = (props) => {
  return (
    <StyledCustomPaper
      elevation={8}
      sx={{ backgroundColor: "white" }}
      {...props}
    />
  );
};
const SEARCH_CITIES = gql`
  query SearchCitiesStates($text: String!) {
    mapCities(search: $text)
  }
`;

const GET_CITY = gql`
  query SearchCitiesStates($text: String!) {
    mapCities(getCity: $text)
  }
`;

const currencyStringToNumber = (str) => Number(str.replace(/[^0-9.-]+/g, ""));

/*
 we took the total households for a city and multiplied it by its mean income, as reported by the US Census Bureau in its 2014 American Community Survey (ACS) (5-Year Estimates), to find its total income. We then divided total income for that city by the total state income to determine the percentage of income generated by it. Finally, we multiplied that percentage by the state’s annual contribution to determine the annual contribution for the city.
 */

export const cityContributionByHouseholds = ({ housing_units }) =>
  (usTotal / 103863921) * housing_units;

const SearchBar = ({ actionsOpen }) => {
  const [text, setText] = useState("");
  const [id, setId] = useState("");

  const { setSelectedPlace } = useContext(PlaceContext);

  const { data, loading, error } = useQuery(SEARCH_CITIES, {
    variables: { text },
  });
  if (error) console.log("Error loading data for search bar", error);
  const setSearchTermDebounced = debounce(setText, 275);

  const processData = () => {
    //for search
    if (data?.mapCities && data.mapCities) {
      let jsonData = JSON.parse(data?.mapCities);
      if (jsonData) {
        jsonData = jsonData.filter(
          (value, index, self) =>
            index ===
            self.findIndex(
              (t) => t.city === value.city && t.state_name === value.state_name
            )
        );
        return jsonData.map((item) => {
          return {
            label: `${item.city}, ${item.state_name}`,
            type: "city",
            ...item,
          };
        });
      } else {
        return [];
      }
    }
    return [];
  };
  const {
    data: updateCityData,
    loading: updateCityLoading,
    error: updateCityError,
  } = useQuery(GET_CITY, {
    variables: { text: id },
  });
  if (updateCityError) {
    console.log("updateCityError in search bar", updateCityLoading, error);
  }
  useEffect(() => {
    if (id !== "" && updateCityData?.mapCities) {
      const updateCityDataJson = JSON.parse(updateCityData.mapCities);
      console.log("city data", updateCityDataJson);
      if (updateCityDataJson.length > 0) {
        const {
          city: name,
          lat,
          lng,
          housing_units,
          state_id,
          state_name,
        } = updateCityDataJson[0];
        setSelectedPlace({
          type: "city",
          name: `${name}, ${state_name}`,
          latLng: [parseFloat(lat), parseFloat(lng)],
          contribution: cityContributionByHouseholds({
            housing_units: parseInt(housing_units),
          }),
          state_id,
          state_name,
        });
      }
    }
  }, [id, updateCityData?.mapCities, setSelectedPlace]);

  return (
    <SearchBarUI
      updateSearchTerm={setSearchTermDebounced}
      cityData={processData(data)}
      cityLoading={loading}
      setId={setId}
      setSelectedPlace={setSelectedPlace}
      actionsOpen={actionsOpen}
    />
  );
};

const SearchBarUI = ({
  updateSearchTerm,
  cityData,
  cityLoading,
  setId,
  setSelectedPlace,
  actionsOpen,
}) => {
  const [autofillData, setAutofillData] = useState([]);
  const [value, setValue] = useState(null);
  const { data, loading, error } = useMapData();

  const handleInputChange = (event, value, reason) => {
    if (value.length > 1) updateSearchTerm(value);
  };
  useMemo(() => {
    if (data) {
      const jsonData = JSON.parse(data.map);
      const processedData = jsonData.map((item) => {
        return { label: item.name, type: "state", ...item };
      });
      setAutofillData(processedData);
    }
  }, [data]);

  useEffect(() => {
    if (cityData.length > 0) {
      setAutofillData((state) => [
        ...state.filter((item) => !item.city),
        ...cityData,
      ]);
    }
  }, [cityData]);

  if (loading) {
    // console.log("loading");
  }
  if (error) {
    console.log("Unable to load item data", error);
  }

  useEffect(() => {
    if (value) {
      const { type } = value;
      if (type === "city") {
        setId(value.id);
      } else {
        const { name, contribution, code } = value;
        console.log("set state",name)
        setSelectedPlace({
          type: "state",
          name,
          contribution: currencyStringToNumber(contribution),
          code,
        });
      }
    }
  }, [value, setId, setSelectedPlace]);

  return (
    <Box component="form" noValidate sx={{ opacity: actionsOpen ? 0.5 : 1 }}>
      <Autocomplete
        disablePortal
        id="combo-box"
        options={autofillData}
        PaperComponent={CustomPaper}
        onInputChange={handleInputChange}
        groupBy={(option) => option.type}
        value={value}
        noOptionsText={"No locations for that search"}
        isOptionEqualToValue={(option, value) => {
          return option.label === value.label;
        }}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            sx={{
              paddingTop: 0,
              paddingBottom: 0,
            }}
          >
            <Typography
              sx={{
                fontWeight: 500,
                letterSpacing: "0.44px",
                fontSize: "16px",
                color: "black",
              }}
            >
              {option.label}
            </Typography>
          </li>
        )}
        renderInput={(params) => (
          <CssTextField
            {...params}
            fullWidth
            label="Type your City or State to Begin&nbsp;"
            InputProps={{
              ...params.InputProps,
              endAdornment: <SearchIcon />,
            }}
            InputLabelProps={{
              sx: {
                color: "#B8B8B8",
                fontFamily: "Raleway",
                fontSize: "16px",
                backgroundColor: "transparent",
              },
            }}
          />
        )}
      />
    </Box>
  );
};

export default SearchBar;
