import { ReactComponent as HomeIcon } from "./icons/home.svg";
import { ReactComponent as HealthcareIcon } from "./icons/healthcare.svg";
import { ReactComponent as TeachersIcon } from "./icons/book.svg";
import { ReactComponent as SolarIcon } from "./icons/solar.svg";
import { ReactComponent as StudentsIcon } from "./icons/debt.svg";
import { ReactComponent as MaskIcon } from "./icons/mask.svg";

const unitCostString = (total, unitCost) => {
  if (total / unitCost > 3000000000) {
    return ">3 billion";
  } else {
    return parseInt(total / unitCost).toLocaleString();
  }
};
const unitCostNumber = (total, unitCost) => {
  if (total / unitCost > 3000000000) {
    return ">3 billion";
  } else {
    return Math.floor(total / unitCost);
  }
};
const offsetMap = {
    home: {
      icon: HomeIcon,
      text: "Households with public housing for a year",
      unitCost: (total) => unitCostString(total, 8412),
      unitCostNumber: (total) => unitCostNumber(total, 8412),
    },
    healthcare: {
      icon: HealthcareIcon,
      text: "Children receiving free or low-cost healthcare",
      unitCost: (total) => unitCostString(total, 2874),
      unitCostNumber: (total) => unitCostNumber(total, 2874),
      
    },
    teachers: {
      icon: TeachersIcon,
      text: "Elementary school teachers",
      unitCost: (total) => unitCostString(total, 91588),
      unitCostNumber: (total) => unitCostNumber(total, 91588),
      
    },
    solar: {
      icon: SolarIcon,
      text: "Households with solar electricity produced for a year",
      unitCost: (total) => unitCostString(total, 351.25),
      unitCostNumber: (total) => unitCostNumber(total, 351.25),

    },
    students: {
      icon: StudentsIcon,
      text: "Students with their loan debt cancelled",
      unitCost: (total) => unitCostString(total, 37787),
      unitCostNumber: (total) => unitCostNumber(total, 37787),

    },
    mask: {
      icon: MaskIcon,
      text: "N95 respirator masks",
      unitCost: (total) => unitCostString(total, 1.15),
      unitCostNumber: (total) => unitCostNumber(total, 1.15),

    },
  };

  export default offsetMap;