import { ReactComponent as HomeIcon } from "./icons/home.svg";
import { ReactComponent as HealthcareIcon } from "./icons/healthcare.svg";
import { ReactComponent as TeachersIcon } from "./icons/book.svg";
import { ReactComponent as SolarIcon } from "./icons/solar.svg";
import { ReactComponent as StudentsIcon } from "./icons/debt.svg";
import { ReactComponent as GroceriesIcon } from "./icons/groceries.svg";

const unitCostString = (total, unitCost) => {
  return parseInt(total / unitCost).toLocaleString();
};
const unitCostNumber = (total, unitCost) => {
  return Math.floor(total / unitCost);

};
const offsetMap = {
  mask: {
    icon: HomeIcon,
    text: "Households with one month's free rent",
    unitCost: (total) => unitCostString(total, 1595),
    unitCostNumber: (total) => unitCostNumber(total, 1595),

  },
    home: {
      icon: GroceriesIcon,
      text: "Families with one month's free groceries",
      unitCost: (total) => unitCostString(total, 475.25),
      unitCostNumber: (total) => unitCostNumber(total, 475.25),
    },
    healthcare: {
      icon: HealthcareIcon,
      text: "Children's free or low-cost healthcare for one year",
      unitCost: (total) => unitCostString(total, 2874),
      unitCostNumber: (total) => unitCostNumber(total, 2874),
      
    },
    teachers: {
      icon: TeachersIcon,
      text: "Elementary school teachers' salaries for one year",
      unitCost: (total) => unitCostString(total, 91588),
      unitCostNumber: (total) => unitCostNumber(total, 91588),
      
    },
    solar: {
      icon: SolarIcon,
      text: "Households with solar electricity produced for a year",
      unitCost: (total) => unitCostString(total, 351.25),
      unitCostNumber: (total) => unitCostNumber(total, 351.25),

    },
    students: {
      icon: StudentsIcon,
      text: "Students with their loan debt cancelled",
      unitCost: (total) => unitCostString(total, 37787),
      unitCostNumber: (total) => unitCostNumber(total, 37787),

    },

  };

  export default offsetMap;