import { createTheme } from "@mui/material";

const theme = createTheme({
  typography: {
    fontFamily: ["Raleway", "Oswald", "Arial"].join(","),
    fontWeightExtraBold: 800,
    subtitle1: {
      fontFamily: "Raleway",
      fontSize: "24px",
      fontWeight: 500,
      letterSpacing: "0.75px",
      lineHeight: "28px",
    },
    body1: {
      // fontFamily: "Oswald",
      fontSize: "26px",
      fontWeight: 700,
      letterSpacing: "0.81px",
    },
    body2: {
      fontFamily: "ralewaymedium",
      fontSize: "16px",
      fontWeight: 500,
      letterSpacing: "0.5px",
    },
    subtitle2: {
      fontFamily: "Raleway",
      fontSize: "16px",
      fontWeight: 800,
      letterSpacing: "0.5px",
      textTransform: "uppercase",
      color: "#FFFFFF",
    },
    caption: {},
  },

  palette: {
    primary: {
      main: "#EE3624",
    },
    secondary: {
      main: "#FFFFFF",
    },
    background: "#262626",
    darkGrey: "#353535",
    lightGrey: "#B8B8B8",
    maroon: "#841618",
    midGrey: "#474747",
    red: "#EE3624",
  },
});

export default theme;
