import { useRef } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import ActionItems from "./ActionItems";
import useMediaQuery from "@mui/material/useMediaQuery";
import { styled } from "@mui/material/styles";
const StyledButton = styled((props) => <Button disableRipple {...props} />)(
  ({ theme }) => ({
    fontFamily: "Oswald",
    borderRadius: 0,
    fontWeight: 700,
    letterSpacing: "0.81px",
    paddingBottom: "8px",
    fontSize: "26px !important",
    "&:hover": {
      boxShadow: "none",
    },
    "& :focus": {
      backgroundColor: theme.palette.primary.main,
    },
    boxShadow: "none",
  })
);

const StyledDrawer = styled(Box)(({ theme, open, contentheight }) => ({
  position: "absolute",
  width: "calc(100% - 48px)",
  bottom: -contentheight, //height of drawer content + padding
  transform: open ? `translateY(-${contentheight}px)` : `translateY(0)`,
  transition: "0.6s ease-in-out",
  backgroundColor: theme.palette.primary.main,
  zIndex: 90,
}));

const ActionButton = ({ actionsOpen, setActionsOpen }) => {
  // const [open, setOpen] = useState(false);
  const contentRef = useRef(null);
  const buttonRef = useRef(null);
  const matches = useMediaQuery("(min-width:600px)");
// const [contentHeight,setContentHeight] = useState(402)
  // useEffect(() => {
  //   setContentHeight(contentRef.current?.clientHeight);
  // }, []);
  const handleTakeActionClick = (b) => {
    setActionsOpen(b);
  };
  return matches ? (
    <StyledDrawer
      open={actionsOpen}
      contentheight={424 - 53}
      onMouseLeave={() => {
        if (actionsOpen) handleTakeActionClick(false);
      }}
    >
      <div ref={buttonRef}>
        <StyledButton
          variant="contained"
          onMouseEnter={() => handleTakeActionClick(true)}
          onClick={() => handleTakeActionClick(!actionsOpen)}
          fullWidth
        >
          Take Action
        </StyledButton>
      </div>
      <Box
        sx={{ pl: 1, pr: 1, mt: 0 }}
        ref={contentRef}
        onMouseLeave={() => handleTakeActionClick(!actionsOpen)}
      >
        <ActionItems />
      </Box>
    </StyledDrawer>
  ) : (
    <>
      <StyledButton variant="contained" fullWidth>
        Take Action
      </StyledButton>
      <Box sx={{ pl: 1, pr: 1, backgroundColor: "red" }} ref={contentRef}>
        <ActionItems />
      </Box>
    </>
  );
};

export default ActionButton;
