import { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import PanelContent from "./PanelContent";
import { PlaceContext, useMapData, countryValues } from "../../data/MapContext";
const currencyStringToNumber = (str) => Number(str.replace(/[^0-9.-]+/g, ""));

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    fontWeight: theme.typography.fontWeightExtraBold,
    fontSize: "16px",
    textTransform: "uppercase",
    letterSpacing: "0.5px",
    paddingBottom: 0,
    color: "#FFFFFF",
    zIndex: 1,
  })
);

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    bottom: "9px",
    height: "7px",
  },
  "& .MuiTabs-indicatorSpan": {
    width: "100%",
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const tabsMap = {
  country: 0,
  state: 1,
  city: 2,
};

const TabsNav = ({ actionsOpen, setActionsOpen }) => {
  const [value, setValue] = useState(0);
  const { selectedPlace, setSelectedPlace } = useContext(PlaceContext);
  const { data } = useMapData();

  const handleChange = (event, newValue) => {
    if (newValue === 0) setSelectedPlace(countryValues);
    if (selectedPlace.type === "city" && newValue === 1) {
      const jsonData = JSON.parse(data.map);
      const item = jsonData.filter(
        (state) => state.code === selectedPlace.state_id
      );
      const { name, code, contribution } = item[0];
      setSelectedPlace({
        type: "state",
        name,
        contribution: currencyStringToNumber(contribution),
        code,
      });
    }
    setValue(newValue);
  };
  useEffect(() => {
    setValue(tabsMap[selectedPlace.type]);
  }, [selectedPlace.type]);
  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider",opacity: actionsOpen ? 0.5 : 1 }}>
        <StyledTabs
          value={value}
          onChange={handleChange}
          aria-label="Tabs for Map selection and info"
          indicatorColor="primary"
          textColor="secondary"
        >
          <StyledTab label="The U.S." {...a11yProps(0)} />
          <StyledTab label="State" {...a11yProps(1)} />
          <StyledTab label="City" {...a11yProps(2)} />
        </StyledTabs>
      </Box>
      <TabPanel value={value} index={0}>
        <PanelContent
          value={0}
          actionsOpen={actionsOpen}
          setActionsOpen={setActionsOpen}
        />
      </TabPanel>

      <TabPanel value={value} index={1}>
        <PanelContent
          value={1}
          actionsOpen={actionsOpen}
          setActionsOpen={setActionsOpen}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <PanelContent
          value={2}
          actionsOpen={actionsOpen}
          setActionsOpen={setActionsOpen}
        />
      </TabPanel>
    </>
  );
};

export default TabsNav;
